@font-face {
  font-family: 'LouisVuitton_Demi';
  src: url('./fonts/LouisVuitton-Demi.ttf') format('truetype');
}
@font-face {
  font-family: 'LouisVuitton_Medium';
  src: url('./fonts/LouisVuitton-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'LouisVuitton_Regular';
  src: url('./fonts/LouisVuitton-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'LouisVuitton_Bold';
  src: url('https://tp.louisvuitton.com/fonts/bin/LouisVuitton-Demi_logo.woff')
    format('woff');
}
